import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import CoursesList from '../../components/CoursesList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "คอร์สทั้งหมดที่เปิดสอน",
      "style": {
        "position": "relative"
      }
    }}>{`คอร์สทั้งหมดที่เปิดสอน`}<a parentName="h1" {...{
        "href": "#%E0%B8%84%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%AA%E0%B8%97%E0%B8%B1%E0%B9%89%E0%B8%87%E0%B8%AB%E0%B8%A1%E0%B8%94%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%9B%E0%B8%B4%E0%B8%94%E0%B8%AA%E0%B8%AD%E0%B8%99",
        "aria-label": "คอร์สทั้งหมดที่เปิดสอน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <p>{`คอร์สทั้งหมดสามารถเรียนได้ทั้งออนไลน์และออฟไลน์ ดูตามรายละเอียดของคอร์สครับ`}</p>
    <CoursesList mdxType="CoursesList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      